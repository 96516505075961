import { Controller } from '@stimulus/core';

export default class ConversationsController extends Controller {
  public static targets = ['details', 'conversation', 'headerConversations', 'messages', 'translate', 'textarea'];

  private readonly detailsTarget: HTMLElement;
  private readonly conversationTarget: HTMLElement;
  private readonly headerConversationsTarget: HTMLElement;
  private readonly messagesTarget: HTMLElement;
  private readonly translateTarget: HTMLElement;
  private readonly textareaTarget: HTMLTextAreaElement;

  public connect() {
    this.scrollToBottom();
    this.textareaTarget.addEventListener('input', this.autoGrow.bind(this));
  }

  public archive(event: Event) {
    alert("archive");
  }

  public mobileShowDetails(event: Event) {
    this.detailsTarget.classList.add('show');
  }

  public mobileHideDetails(event: Event) {
    this.detailsTarget.classList.remove('show');
  }

  public mobileShowConversation(event: Event) {
    this.conversationTarget.classList.add('show');
    this.scrollToBottom();
  }

  public mobileHideConversation(event: Event) {
    this.conversationTarget.classList.remove('show');
  }

  public toggleSearch(event: Event) {
    this.headerConversationsTarget.classList.toggle('show-search');
  }

  public removeTranslate(event: Event) {
    if (this.translateTarget) {
      this.translateTarget.remove();
    }
  }

  private scrollToBottom() {
    this.messagesTarget.scrollTop = this.messagesTarget.scrollHeight;
  }

  private autoGrow() {
    // Check if the cursor is at the end of the textarea
    const isAtEnd = this.textareaTarget.selectionStart === this.textareaTarget.value.length;
    
    // Reset the height to 'auto' to recalculate the required height
    this.textareaTarget.style.height = 'auto';
  
    // Calculate the padding height
    const style = getComputedStyle(this.textareaTarget);
    const paddingTop = parseInt(style.paddingTop);
    const paddingBottom = parseInt(style.paddingBottom);
  
    // Calculate the total height based on the number of lines and the padding
    const lineHeight = parseInt(style.lineHeight);
    let lines = this.textareaTarget.value.split('\n').length;
    if (lines > 4) {
      lines = 4;
    }
    const newHeight = (lineHeight * lines) + paddingTop + paddingBottom;
  
    // Set the new height of the textarea
    this.textareaTarget.style.height = `${newHeight}px`;
  
    // Scroll to the bottom if the number of lines is more than 3 and the cursor is at the end
    if (lines >= 4 && isAtEnd) {
      this.textareaTarget.scrollTop = this.textareaTarget.scrollHeight;
    }
  }
  
  
}
